import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  isDevMode,
  APP_INITIALIZER,
  ErrorHandler,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { RedirectionComponent } from './component/error/redirection/redirection.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Router } from 'express';
import * as Sentry from '@sentry/angular';
import { DashboardModule } from './component/dashboard/dashboard.module';
// import { FloatingButtonsModule } from './component/floating-buttons/floating-buttons.module';
import { EnddetailsModule } from './component/enddetails/enddetails.module';
import { HomeAnnexModule } from './component/home-annex/home-annex.module';
import { SouscriptionModule } from './component/souscription/souscription.module';
import { environment } from 'src/environments/environment';
import { PopupBlackfridayModule } from './component/popup-blackfriday/popup-blackfriday.module';

@NgModule({
  declarations: [AppComponent, RedirectionComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DashboardModule,
    // FloatingButtonsModule,
    EnddetailsModule,
    SouscriptionModule,
    HomeAnnexModule,
    // PopupBlackfridayModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],

  providers: [
    provideClientHydration(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    /*** DO NOT UNCOMMENT BECAUSE IT WILL CREATE A BIG ISSUE OF POLYFILL THIRD PARTY LIBRAIRIES */
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  // Do not remove trace dependency as it is used for error trackers
  constructor(trace: Sentry.TraceService) {}
}
